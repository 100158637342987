import React from 'react';
import { Box, Input, InputProps } from '@grupoboticario/flora-react';
import { useFormContext } from 'react-hook-form';

export interface FormInputProps extends InputProps {
  registerName: string;
  errorMessage?: string;

  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setValueAs?: (value: string) => unknown;
  isRequired?: boolean;
}

const FormInput = ({
  registerName,
  onBlur,
  onChange,
  setValueAs,
  disabled,
  readOnly,
  optionalText = '',
  isRequired,
  errorMessage = 'Campo obrigatório',
  ...rest
}: FormInputProps): JSX.Element => {
  const { register, getFieldState, formState } = useFormContext();
  const fieldState = getFieldState(registerName, formState);

  return (
    <Box
      css={{
        '& > div': {
          height: 'auto',
        },
      }}
    >
      <Input
        invalidText={disabled ? undefined : fieldState.error?.message}
        invalid={disabled ? undefined : !!fieldState.error}
        data-valid={disabled ? undefined : fieldState.isDirty && !fieldState.error}
        css={
          readOnly
            ? {
                backgroundColor: '$disabledAltBackground',
                color: '$nonInteractiveAuxiliar',
              }
            : rest.css
        }
        optionalText={optionalText}
        readOnly={readOnly}
        {...rest}
        {...register(registerName, {
          disabled,
          onBlur,
          onChange,
          setValueAs: setValueAs,
          required: isRequired ? errorMessage : undefined,
        })}
      />
    </Box>
  );
};

export { FormInput };
