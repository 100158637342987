export enum EventName {
  CALLBACK_DADOS_CADASTRAIS_CODIGO_CS = 'callback_dados_cadastrais_codigo_cs',
  CALLBACK_DADOS_CADASTRAIS_ESTADO = 'callback_dados_cadastrais_estado',
  INTERACTION_CENTRAIS_DE_SERVICO = 'interaction_centrais_de_servico',
  INTERACTION_CENTRAIS_SERVICO_DADOS_CADASTRAIS = 'interaction_centrais_servico_dados_cadastrais',
  INTERACTION_CENTRAIS_SERVICO_DOCUMENTACAO = 'interaction_centrais_servico_documentacao',
  INTERACTION_CENTRAIS_SERVICO_HORARIO_FUNCIONAMENTO = 'interaction_centrais_servico_horario_funcionamento',
  INTERACTION_CENTRAIS_SERVICO_INCREMENTO_PEDIDO = 'interaction_centrais_servico_incremento_pedido',
  INTERACTION_CENTRAIS_SERVICO_INICIA_COM_PRODUTOS = 'interaction_centrais_servico_inicia_com_produtos',
  INTERACTION_CENTRAIS_SERVICO_RETIRADA = 'interaction_centrais_servico_retirada',
  INTERACTION_CENTRAIS_SERVICO_ENTREGA = 'interaction_centrais_servico_entrega',
  INTERACTION_CENTRAIS_SERVICO_BLOQUEIO_E_LIMITE = 'interaction_centrais_servico_bloqueio_e_limite',
  INTERACTION_CENTRAIS_SERVICO_FORMAS_DE_PAGAMENTO = 'interaction_centrais_servico_formas_de_pagamento',
}
