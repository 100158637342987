import { saveServiceCenter, events } from '@/api';
import { useServiceCenterForm } from '@/context';
import { OrderIncrementFormData, ProductCategory } from '@/types';
import { Toaster, Box } from '@grupoboticario/flora-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useMemo } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import {
  FormWrapper,
  FormFieldset,
  InputSwitch,
  TogglableContent,
  FormInput,
  InputMinMaxSection,
  InputNote,
  FormFooter,
  ChipInput,
  GroupCheckbox,
} from '@/components/';
import {
  formatOrderIncrementFormData,
  normalizeOrderIncrementFormData,
  orderIncrementFormDataSchema,
} from '@/components/order-increment-form/';
import { productCategoryValueLabelMap } from '@/labels';
import { useEvents } from '@/event-tracker';

const generateDefaultValues = (): OrderIncrementFormData => ({
  allowIncrement: false,
  hasCategories: false,
  hasMaximumValue: false,
  hasMinimumValue: false,
  hasRequiredQuantity: false,
  hasSpecificProducts: false,
});

const categories: {
  label: string;
  value: ProductCategory;
  id: string;
}[] = [
  {
    id: 'perfumaria',
    label: productCategoryValueLabelMap[ProductCategory.PERFUMARIA],
    value: ProductCategory.PERFUMARIA,
  },
  {
    id: 'maquiagem',
    label: productCategoryValueLabelMap[ProductCategory.MAQUIAGEM],
    value: ProductCategory.MAQUIAGEM,
  },
  {
    id: 'corpo-e-banho',
    label: productCategoryValueLabelMap[ProductCategory.CORPO_E_BANHO],
    value: ProductCategory.CORPO_E_BANHO,
  },
  {
    id: 'cabelos',
    label: productCategoryValueLabelMap[ProductCategory.CABELOS],
    value: ProductCategory.CABELOS,
  },
  {
    id: 'pele',
    label: productCategoryValueLabelMap[ProductCategory.PELE],
    value: ProductCategory.PELE,
  },
];

const handleDataSubmit = async (data: OrderIncrementFormData, csId?: string) => {
  if (csId === undefined) {
    throw new Error('Service Center ID não pode ser nulo');
  }

  const formattedData = formatOrderIncrementFormData(data, csId);
  return saveServiceCenter(formattedData);
};

const OrderIncrementForm = (): JSX.Element => {
  const { goToNextStep, state, setOrderIncrement, isEditMode } = useServiceCenterForm();
  const eventTracker = useEvents();

  const defaultValues = useMemo(
    () => state.formData.orderIncrement ?? generateDefaultValues(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const methods = useForm<OrderIncrementFormData>({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    resolver: yupResolver(orderIncrementFormDataSchema),
    mode: 'onBlur',
    defaultValues,
  });

  const {
    formState: { isDirty, isSubmitting },
  } = methods;

  useEffect(() => {
    events.emit('UPDATE_CS_DATA', {
      isSubmitting,
    });
  }, [isSubmitting]);

  const shouldSkipRequestOnSubmit = useMemo(
    () => !isDirty && isEditMode && !!state.formData.orderIncrement,
    [isDirty, isEditMode, state.formData.orderIncrement],
  );

  const onSubmitOrderIncrementData = useCallback(
    async (data: OrderIncrementFormData) => {
      try {
        if (shouldSkipRequestOnSubmit) {
          goToNextStep();
          return;
        }

        const response = await handleDataSubmit(data, state.formData.id);

        if (!response.orderIncrement) {
          throw new Error('Formato de objeto não suportado, esperado OrderIncrement');
        }

        setOrderIncrement(normalizeOrderIncrementFormData(response.orderIncrement));
        goToNextStep();
      } catch (e) {
        Toaster.notify({
          kind: 'error',
          description: 'Um erro ocorreu ao salvar esta etapa. Por favor, tente novamente mais tarde.',
          origin: 'right-top',
          duration: 60000,
          button: 'Fechar',
          closeWhenClick: true,
        });
      }
    },
    [goToNextStep, shouldSkipRequestOnSubmit, setOrderIncrement, state.formData.id],
  );

  const allowIncrementValue = methods.watch('allowIncrement');

  return (
    <FormProvider {...methods}>
      <Box as="form" onSubmit={methods.handleSubmit(onSubmitOrderIncrementData)}>
        <FormWrapper gap="$2">
          <FormFieldset hasDivider={false}>
            <InputSwitch
              label="Permite adicionar produtos, além do kit inicial?"
              registerName="allowIncrement"
              onText="Sim"
              offText="Não"
              onCheckedChange={isEnable => {
                eventTracker.toggleAddProductsPermission(isEnable);
              }}
            />
          </FormFieldset>
        </FormWrapper>

        <FormWrapper title="Condições gerais" gap="$2" css={{ marginTop: '$6' }}>
          <FormFieldset hasDivider={false}>
            <TogglableContent
              title="Obrigatório incrementar?"
              switchRegisterName="hasRequiredQuantity"
              disabled={!allowIncrementValue}
              switchEventTracker={isEnable => eventTracker.toggleIncrementRequirement(isEnable)}
            >
              <FormInput
                registerName="requiredQuantity"
                id="requiredQuantity"
                labelText="Quantidade obrigatória:"
                optionalText=""
                placeholder="__"
                data-testid="required-quantity-testid"
                defaultValue={defaultValues.requiredQuantity}
                disabled={!allowIncrementValue}
                onBlur={event => eventTracker.inputRequiredQuantity(event.target.value)}
              />
            </TogglableContent>
          </FormFieldset>

          <InputMinMaxSection
            disabled={!allowIncrementValue}
            inputMinEventTracker={minValue => eventTracker.inputMinimumValue(minValue)}
            inputMaxEventTracker={maxValue => eventTracker.inputMaximumValue(maxValue)}
          />

          <FormFieldset>
            <TogglableContent
              title="Produtos específicos?"
              switchRegisterName="hasSpecificProducts"
              disabled={!allowIncrementValue}
              switchEventTracker={isEnable => eventTracker.toggleSpecificProducts(isEnable)}
            >
              <ChipInput
                id="specificProducts"
                labelText="Produtos:"
                registerName="specificProducts"
                optionalText=""
                placeholder="Digitar SKU's separados por ponto e vírgula. Ex: 32913;23131;44442"
                data-testid="specific-products-testid"
                disabled={!allowIncrementValue}
                inputEventTracker={skus => eventTracker.inputSpecificProducts(skus)}
              />
            </TogglableContent>
          </FormFieldset>

          <FormFieldset>
            <Box css={{ gridColumn: 'span 12' }}>
              <TogglableContent
                title="Categoria de produtos específica?"
                switchRegisterName="hasCategories"
                disabled={!allowIncrementValue}
                switchEventTracker={isEnable => eventTracker.toggleSpecificProductCategory(isEnable)}
              >
                <GroupCheckbox
                  checkboxes={categories}
                  registerName="categories"
                  checkboxesEventTracker={category => eventTracker.selectSpecificProductCategory(category)}
                />
              </TogglableContent>
            </Box>
          </FormFieldset>

          <FormFieldset>
            <InputNote registerName="note" disabled={!allowIncrementValue} />
          </FormFieldset>
        </FormWrapper>

        <FormFooter />
      </Box>
    </FormProvider>
  );
};

export { OrderIncrementForm };
