import { saveAndContinueAction, toggleInteraction } from '../functions';
import { InteractionDetail, EventName, EventTracker } from '../types';

interface OrderIncrementTrackerProps {
  tracker: EventTracker;
  interactionDetail: string;
}

const orderIncrementTracker = ({ tracker, interactionDetail }: OrderIncrementTrackerProps): void => {
  tracker.track({
    event: 'analytics-event',
    event_parameters: {
      event_name: EventName.INTERACTION_CENTRAIS_SERVICO_INCREMENTO_PEDIDO,
      cd_interaction_detail: interactionDetail,
    },
  });
};

export const orderIncrementEvents = (tracker: EventTracker) => {
  return {
    toggleAddProductsPermission: (isEnable: boolean) =>
      orderIncrementTracker({
        tracker,
        interactionDetail: toggleInteraction(isEnable, 'adicionar-produtos'),
      }),
    toggleIncrementRequirement: (isEnable: boolean) =>
      orderIncrementTracker({
        tracker,
        interactionDetail: toggleInteraction(isEnable, 'obrigatorio-incrementar'),
      }),
    inputRequiredQuantity: (quantity: string) => {
      orderIncrementTracker({
        tracker,
        interactionDetail: `${InteractionDetail.input}:quantidade-obrigatoria-${quantity}`,
      });
    },
    toggleMinimumValue: (isEnable: boolean) =>
      orderIncrementTracker({
        tracker,
        interactionDetail: toggleInteraction(isEnable, 'valor-minimo'),
      }),
    inputMinimumValue: (value: string) => {
      orderIncrementTracker({
        tracker,
        interactionDetail: `${InteractionDetail.input}:valor-minimo-${value}`,
      });
    },
    toggleMaximumValue: (isEnable: boolean) =>
      orderIncrementTracker({
        tracker,
        interactionDetail: toggleInteraction(isEnable, 'valor-maximo'),
      }),
    inputMaximumValue: (value: string) => {
      orderIncrementTracker({
        tracker,
        interactionDetail: `${InteractionDetail.input}:valor-maximo-${value}`,
      });
    },
    toggleSpecificProducts: (isEnable: boolean) =>
      orderIncrementTracker({
        tracker,
        interactionDetail: toggleInteraction(isEnable, 'produtos-especificos'),
      }),
    inputSpecificProducts: (skus: string) => {
      orderIncrementTracker({
        tracker,
        interactionDetail: `${InteractionDetail.input}:produtos-especificos-${skus}`,
      });
    },
    toggleSpecificProductCategory: (isEnable: boolean) =>
      orderIncrementTracker({
        tracker,
        interactionDetail: toggleInteraction(isEnable, 'categoria-produtos-especifica'),
      }),

    selectSpecificProductCategory: (categoryName: string) => {
      orderIncrementTracker({
        tracker,
        interactionDetail: `${InteractionDetail.click}:categoria-produtos-especifica-${categoryName}`,
      });
    },
    saveAndContinue: () =>
      saveAndContinueAction({ tracker, eventName: EventName.INTERACTION_CENTRAIS_SERVICO_INCREMENTO_PEDIDO }),
  };
};
