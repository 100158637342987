import { saveAndContinueAction, toggleInteraction } from '../functions';
import { EventName, EventTracker } from '../types';

export const documentationEvents = (tracker: EventTracker) => {
  return {
    toggleAdditionalDocumentation: (isEnable: boolean) => {
      tracker.track({
        event: 'analytics-event',
        event_parameters: {
          event_name: EventName.INTERACTION_CENTRAIS_SERVICO_DOCUMENTACAO,
          cd_interaction_detail: toggleInteraction(isEnable, 'solicitar-documentacao-adicional'),
        },
      });
    },
    documentationSaveAndContinue: () =>
      saveAndContinueAction({ tracker, eventName: EventName.INTERACTION_CENTRAIS_SERVICO_DOCUMENTACAO }),
  };
};
