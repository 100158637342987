import {
  businessHoursEvents,
  createChecklistEvents,
  documentationEvents,
  franchiseEvents,
  orderIncrementEvents,
} from '../events';
import { CustomWindow, GtmEvent } from '../types';

const useEvents = () => {
  const tracker = {
    track(params: GtmEvent) {
      const win: CustomWindow = window;

      if (Array.isArray(win.dataLayer)) {
        win.dataLayer.push(params);
      }
    },
  };

  return {
    ...businessHoursEvents(tracker),
    ...createChecklistEvents(tracker),
    ...documentationEvents(tracker),
    ...franchiseEvents(tracker),
    ...orderIncrementEvents(tracker),
  };
};

export { useEvents };
