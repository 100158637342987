import { ControlledSwitch } from '@/components';
import { Flex, FloraTypography, Tooltip } from '@grupoboticario/flora-react';
import { ChevronDownIcon, ChevronUpIcon, QuestionMarkCircleIcon } from '@grupoboticario/flora-react-icons';
import React, { useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';

interface TogglableContentProps {
  title: string;
  switchRegisterName: string;
  disabled?: boolean;
  leftIcon?: JSX.Element;
  isCollapsible?: boolean;
  tooltipText?: string;
  switchEventTracker?: (e: boolean) => void;
}

const TogglableContent = ({
  children,
  title,
  switchRegisterName,
  disabled = false,
  leftIcon,
  isCollapsible = false,
  tooltipText,
  switchEventTracker,
}: React.PropsWithChildren<TogglableContentProps>): JSX.Element => {
  const isToggleOn = useWatch({ name: switchRegisterName });
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(isToggleOn);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isToggleOn]);

  return (
    <>
      <Flex justify={'space-between'} align="center" gap="$4">
        <Flex gap={'$2'}>
          {leftIcon && <Flex css={{ color: '$nonInteractiveAuxiliar' }}>{leftIcon}</Flex>}
          <FloraTypography css={{ fontWeight: '$medium' }} fontSize="bodyLargeShort">
            {title}
          </FloraTypography>
          {tooltipText && (
            <Tooltip showArrow size="standard" text={tooltipText} icon={<QuestionMarkCircleIcon />}>
              <QuestionMarkCircleIcon size={16} color="$nonInteractiveAuxiliar" />
            </Tooltip>
          )}
        </Flex>
        <Flex>
          <ControlledSwitch
            onText="Sim"
            offText="Não"
            registerName={switchRegisterName}
            disabled={disabled}
            id={switchRegisterName}
            onCheckedChange={isEnable => {
              if (switchEventTracker) {
                switchEventTracker(isEnable);
              }
            }}
          />

          {isCollapsible && (
            <Flex
              onClick={() => {
                if (isToggleOn) {
                  setIsOpen(prevState => !prevState);
                }
              }}
              css={{
                cursor: isToggleOn ? 'pointer' : 'not-allowed',
                color: isToggleOn ? '$actionableDefault' : '$disabledAltBackground',
              }}
            >
              {isOpen ? <ChevronUpIcon size={24} /> : <ChevronDownIcon size={24} />}
            </Flex>
          )}
        </Flex>
      </Flex>
      {isOpen && children ? (
        <Flex direction="column" css={{ margin: '$4 0' }}>
          {children}
        </Flex>
      ) : null}
    </>
  );
};

export { TogglableContent };
