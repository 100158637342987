import { Box, Input, InputProps } from '@grupoboticario/flora-react';
import { Controller, useFormContext } from 'react-hook-form';

interface FormInputCurrencyProps extends InputProps {
  registerName: string;
}

const FormInputCurrency = ({ registerName, disabled, ...rest }: FormInputCurrencyProps) => {
  const {
    formState: { errors, dirtyFields },
    control,
  } = useFormContext();

  return (
    <Box
      css={{
        '& > div': {
          height: 'auto',
        },
      }}
    >
      <Controller
        name={registerName}
        control={control}
        render={({ field }) => (
          <Input
            {...field}
            key={registerName}
            id={registerName}
            invalidText={disabled ? undefined : (errors[registerName]?.message as string)}
            invalid={disabled ? undefined : !!errors[registerName]}
            data-valid={disabled ? undefined : dirtyFields[registerName] && !errors[registerName]}
            disabled={disabled}
            mask={{
              style: 'currency',
            }}
            {...rest}
          />
        )}
      />
    </Box>
  );
};

export { FormInputCurrency };
