import { formatCurrencyStringToNumber } from '@/utils';
import * as yup from 'yup';

const INVALID_VALUE_ERROR_MESSAGE = 'O valor máximo deve ser maior que o valor máximo.';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const hasValue = (path: string, formValues: any) => {
  const keyMap = {
    minimumValue: 'hasMinimumValue',
    maximumValue: 'hasMaximumValue',
  };
  return !!formValues[keyMap[path as 'minimumValue' | 'maximumValue']];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const currencyValueRangeTestFn = (path: string, formValues: any) => {
  if (!hasValue(path, formValues)) {
    return true;
  }

  const { minimumValue, maximumValue } = formValues;
  const formattedMinimumValue = formatCurrencyStringToNumber(minimumValue);
  const formattedMaximumValue = formatCurrencyStringToNumber(maximumValue);
  const minValue = minimumValue ? Number.parseFloat(formattedMinimumValue) : Number.NaN;
  const maxValue = maximumValue ? Number.parseFloat(formattedMaximumValue) : Number.NaN;

  if (!Number.isNaN(minValue) && !Number.isNaN(maxValue)) {
    return maxValue > minValue;
  }

  return true;
};

export const currencyValueRangeTest: yup.TestConfig<string | undefined> = {
  name: 'currency-value-range-test',
  message: INVALID_VALUE_ERROR_MESSAGE,
  test() {
    return currencyValueRangeTestFn(this.path, this.parent);
  },
};
