import { InteractionDetail, EventName, EventTracker } from '../types';

export const createChecklistEvents = (tracker: EventTracker) => {
  return {
    createChecklist: () => {
      tracker.track({
        event: 'analytics-event',
        event_parameters: {
          event_name: EventName.INTERACTION_CENTRAIS_DE_SERVICO,
          cd_interaction_detail: `${InteractionDetail.click}:adicionar-central-de-servico`,
        },
      });
    },
    editChecklist: () => {
      tracker.track({
        event: 'analytics-event',
        event_parameters: {
          event_name: EventName.INTERACTION_CENTRAIS_DE_SERVICO,
          cd_interaction_detail: `${InteractionDetail.click}:editar-central-de-servico`,
        },
      });
    },
  };
};
