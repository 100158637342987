import { Grid, Flex, Container, FloraTypography, Box, ScrollArea, FloraButton } from '@grupoboticario/flora-react';
import { PlusIcon } from '@grupoboticario/flora-react-icons';
import { useNavigate } from 'react-router-dom';
import { DrawerInfo, ServiceCenterTable } from './components';
import { useEvents } from './event-tracker';

export const ServiceCenter = () => {
  const navigate = useNavigate();
  const events = useEvents();

  const handleCreateServiceCenter = () => {
    events.createChecklist();
    navigate('criar');
  };

  return (
    <Grid css={{ height: '100%' }} templateRows="6rem auto">
      <Flex
        align="center"
        css={{ height: '100%', width: '100%', backgroundColor: '$statusAlertNonInteractiveAltPredominant' }}
      >
        <Container size="large">
          <Flex justify="space-between">
            <FloraTypography as="h6" fontSize="exceptionsAuxiliar" css={{ fontWeight: '$medium' }}>
              Centrais de Serviço
            </FloraTypography>
            <FloraButton
              icon={<PlusIcon />}
              has="iconLeft"
              css={{ padding: '$4 $7' }}
              onClick={handleCreateServiceCenter}
            >
              Adicionar Central de Serviço
            </FloraButton>
          </Flex>
        </Container>
      </Flex>
      <DrawerInfo />
      <Box css={{ overflow: 'hidden' }}>
        <ScrollArea css={{ height: '100%', width: '100%' }} orientation="vertical">
          <Container size="large">
            <Flex css={{ padding: '$9 0' }}>
              <ServiceCenterTable />
            </Flex>
          </Container>
        </ScrollArea>
      </Box>
    </Grid>
  );
};
