import { useRequest } from '@/api';
import { PostalServiceData, PostalServiceDataResponse } from '@/types';

export function useGetPostalService(zipCodeInfo?: string): PostalServiceDataResponse {
  const { data, error } = useRequest<PostalServiceData>(
    zipCodeInfo ? [zipCodeInfo, 'POSTAL_SERVICE'] : null,
    'utils/postalservice',
    {
      searchParams: {
        zipCode: zipCodeInfo as string,
      },
    },
  );

  const noResultsFound = error?.response?.status === 404;

  return {
    isLoading: !data && !error,
    data,
    error,
    noResultsFound,
  };
}
