import { useEvents } from '@/event-tracker';
import { FloraButton } from '@grupoboticario/flora-react';
import { PencilIcon } from '@grupoboticario/flora-react-icons';
import { useNavigate } from 'react-router-dom';

interface TableActionsProps {
  id: string;
}
const TableActions = ({ id }: TableActionsProps): JSX.Element => {
  const navigate = useNavigate();
  const events = useEvents();
  const handleEditServiceCenter = () => {
    events.editChecklist();
    navigate(`editar/${id}`);
  };
  return (
    <FloraButton
      hierarchy="tertiary"
      has="iconOnly"
      icon={<PencilIcon size="18" color="$9" />}
      aria-label="Buscar IR"
      onClick={handleEditServiceCenter}
    />
  );
};

export { TableActions };
