import { saveAndContinueAction } from '../functions';
import { EventName, EventTracker } from '../types';

interface SuccessErrorTrackMessageProps {
  tracker: EventTracker;
  eventName: string;
  errorMessage?: string;
}

const franchiseEventsTracker = ({ tracker, eventName, errorMessage }: SuccessErrorTrackMessageProps): void => {
  const eventSuffix = errorMessage ? 'erro' : 'sucesso';
  tracker.track({
    event: 'analytics-event',
    event_parameters: {
      event_name: `${eventName}_${eventSuffix}`,
      ...(errorMessage && { cd_error_message: errorMessage }),
    },
  });
};

export const franchiseEvents = (tracker: EventTracker) => {
  return {
    inputCsCode: (errorMessage?: string) =>
      franchiseEventsTracker({ tracker, eventName: EventName.CALLBACK_DADOS_CADASTRAIS_CODIGO_CS, errorMessage }),
    inputUf: (errorMessage?: string) =>
      franchiseEventsTracker({ tracker, eventName: EventName.CALLBACK_DADOS_CADASTRAIS_ESTADO, errorMessage }),
    saveAndContinue: () =>
      saveAndContinueAction({ tracker, eventName: EventName.INTERACTION_CENTRAIS_SERVICO_DADOS_CADASTRAIS }),
  };
};
