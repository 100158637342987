import { FormFieldset, FormInputCurrency, TogglableContent } from '@/components';
import { useFormContext } from 'react-hook-form';

interface InputMinMaxSectionProps {
  disabled: boolean;
  inputMinEventTracker?: (e: string) => void;
  inputMaxEventTracker?: (e: string) => void;
}

const InputMinMaxSection = ({
  inputMinEventTracker,
  inputMaxEventTracker,
  disabled = false,
}: InputMinMaxSectionProps): JSX.Element => {
  const { trigger, getValues } = useFormContext();

  const handleBlur = ({ isSwitchEnabled, triggerName }: { isSwitchEnabled: boolean; triggerName: string }) => {
    if (isSwitchEnabled) {
      trigger(triggerName);
    }
  };
  return (
    <>
      <FormFieldset>
        <TogglableContent title="Valor mínimo?" switchRegisterName="hasMinimumValue" disabled={disabled}>
          <FormInputCurrency
            data-testid="minimum-value"
            registerName="minimumValue"
            labelText="Valor:"
            placeholder="R$ 0,00"
            id="minimumValue"
            onBlur={event => {
              handleBlur({ isSwitchEnabled: getValues('hasMinimumValue'), triggerName: 'maximumValue' });
              if (inputMinEventTracker) {
                inputMinEventTracker(event.target.value);
              }
            }}
            disabled={disabled}
          />
        </TogglableContent>
      </FormFieldset>
      <FormFieldset>
        <TogglableContent title="Valor máximo?" switchRegisterName="hasMaximumValue" disabled={disabled}>
          <FormInputCurrency
            data-testid="maximum-value"
            registerName="maximumValue"
            labelText="Valor:"
            placeholder="R$ 0,00"
            id="maximumValue"
            onBlur={event => {
              handleBlur({ isSwitchEnabled: getValues('hasMaximumValue'), triggerName: 'minimumValue' });
              if (inputMaxEventTracker) {
                inputMaxEventTracker(event.target.value);
              }
            }}
            disabled={disabled}
          />
        </TogglableContent>
      </FormFieldset>
    </>
  );
};

export { InputMinMaxSection };
