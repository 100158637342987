import { InteractionDetail, EventTracker } from '../types';

interface SaveAndContinueActionProps {
  tracker: EventTracker;
  eventName: string;
}

export const saveAndContinueAction = ({ tracker, eventName }: SaveAndContinueActionProps): void => {
  tracker.track({
    event: 'analytics-event',
    event_parameters: {
      event_name: eventName,
      cd_interaction_detail: `${InteractionDetail.click}:salvar-continuar`,
    },
  });
};
